var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dialog-form-block-row',{attrs:{"title":"Иконка валюты","desc":"Выберите иконку для вашей валюты. Она будет отображаться у клиентов в приложении. "}},[(_vm.iconSetIdInternal)?_c('v-badge',{attrs:{"overlap":"","avatar":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{on:{"click":_vm.clearIcon}},[_vm._v(" $iconify_ion-close ")])]},proxy:true}],null,false,3388794363)},[_c('v-btn',{staticClass:"bonus-icon-btn",attrs:{"icon":""}},[_c('v-img',{staticClass:"bonus-icon",attrs:{"src":_vm.selectedIconSet.icon_active},on:{"click":_vm.onSelectIcon}})],1)],1):_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.onSelectIcon}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_feather-star ")]),_vm._v(" "),_c('span',[_vm._v("Выбрать иконку")])],1),_c('base-drawer-dialog',{attrs:{"title":"Иконка валюты","stateless":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.selectedIconSet)?_c('dialog-form-block-row',{attrs:{"title":"Пример отображения","desc":"В таком виде клиенты будут видеть иконку в приложении."}},[_c('v-row',{attrs:{"align":"center"}},[_vm._l(([1, 2, 3, 4]),function(n){return [_c('v-col',{key:'icon' + n,class:{
              'bonus-icon-preview': true,
              'bonus-icon-preview--active': n === 1,
            },attrs:{"cols":"auto"}},[_c('v-img',{staticClass:"bonus-icon",class:{
                'bonus-icon': true,
              },attrs:{"src":n === 1
                  ? _vm.selectedIconSet.icon_active
                  : _vm.selectedIconSet.icon_inactive}})],1),_c('v-col',{key:'rect' + n,staticClass:"icon-rect-block",attrs:{"cols":"auto"}},[_c('div',{class:{
                'icon-rect': true,
                'icon-rect--active': n === 1,
              }})])]}),_c('v-col',{class:{
            'bonus-icon-preview-goal': _vm.withGoal,
            'bonus-icon-preview': !_vm.withGoal
          },attrs:{"cols":"auto"}},[_c('v-img',{staticClass:"bonus-icon",attrs:{"src":_vm.withGoal?_vm.selectedIconSet.icon_goal:_vm.selectedIconSet.icon_inactive}})],1)],2)],1):_vm._e(),_c('v-row',_vm._l((_vm.bonusUnitIcons),function(iconSet){return _c('v-col',{key:iconSet.id,attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"bonus-icon-btn",class:{
            'bonus-icon-btn': true,
            'bonus-icon-btn-active': _vm.iconSetIdInternal === iconSet.id,
          },attrs:{"icon":"","large":""}},[_c('v-img',{staticClass:"bonus-icon",attrs:{"src":iconSet.icon_active},on:{"click":function($event){return _vm.onClickIcon(iconSet)}}})],1)],1)}),1),_c('v-row',{staticStyle:{"margin-top":"28px"}},[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.iconSetIdInternal},on:{"click":_vm.onSubmitIcon}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_check-circle ")]),_vm._v(" Выбрать иконку ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }